
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import './App.css';
import { SignUp } from './Auth/SignUp';
import { DealerIn } from './Auth/DealerIn';
import { DealerVerification } from './Auth/DealerVerification';
import { SellerNav } from './Dealer/DealerNav';
import { OpenBid } from './Dealer/OpenBid';
import { CarDetails } from './Dealer/CarDetails';
import { WinnerBid } from './Dealer/WinnerBid';
import { WinnerCarBid } from './Dealer/WinnerCarBid';
import ExpiredCars  from './Dealer/ExpiredCars';
import {ExpiredCarDetails} from './Dealer/ExpiredCarDetails';

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }, []);
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<SignUp />} />
          <Route path="/dealerin" element={<DealerIn />} />
          <Route path="/verification" element={<DealerVerification />} />
          <Route path="/SellerNav" element={<SellerNav />} />
          <Route path="/open-bid/" element={<OpenBid />} />
          <Route path="/car-details/:Vehicle_Id" element={<CarDetails />} />
          <Route path="/winner-bid" element={<WinnerBid />} />
          <Route path="/expired-cars" element={<ExpiredCars />} />
          <Route path="/winner-car-bid/:Vehicle_Id" element={<WinnerCarBid />} />
          <Route path="/expired-car-details/:Vehicle_Id" element={<ExpiredCarDetails />} />
        </Routes>
      </div>
    </Router>

  );
}

export default App;
